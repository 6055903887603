/* cinzel-decorative-400normal - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Cinzel Decorative Regular '),
    local('Cinzel Decorative-Regular'),
    url('./files/cinzel-decorative-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cinzel-decorative-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* cinzel-decorative-700normal - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Cinzel Decorative Bold '),
    local('Cinzel Decorative-Bold'),
    url('./files/cinzel-decorative-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cinzel-decorative-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* cinzel-decorative-900normal - latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Cinzel Decorative Black '),
    local('Cinzel Decorative-Black'),
    url('./files/cinzel-decorative-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cinzel-decorative-latin-900.woff') format('woff'); /* Modern Browsers */
}

